import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export function useCalendarRouting(routeName) {
  const route = useRoute();
  const router = useRouter();

  const startDate     = ref(new Date());
  const currentMonth  = ref(new Date());

  function updateRoute() {
    router.push({
      name: routeName,
      query: {
        month: (currentMonth.value as any).format("YYYY-MM")
      },
      force: true
    });
  }

  function checkQueryParams() {
    if (route.query?.month) {
      startDate.value 
      = currentMonth.value 
      = new Date(`${route.query.month}-01`);
    } 
  }

  return {
    startDate,
    currentMonth,
    updateRoute,
    checkQueryParams
  }
}
