import { Provider } from "@/model/api/Provider";
import { providerTechniciansService } from "@services/providerTechnicians.service";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export function useProviders({technicianId, forceSelection, callback}: { 
  technicianId: number,
  forceSelection?: boolean, 
  callback?: (provider: Provider) => void 
}) {
  const { t } = useI18n();
  
  const selectedProvider = ref<Provider>(null)
  const providers = ref<Provider[]>(null)

  async function loadProviders() {
    const response = 
      await providerTechniciansService.providers(technicianId);

    providers.value = response;
    if (forceSelection && response.length > 0) {
      selectedProvider.value = response[0];
    }
  }

  watch(selectedProvider, (value, old) => {
    if (value !== old) {
      callback?.(selectedProvider.value);
    }
  })

  loadProviders();

  return {
    providers, 
    selectedProvider
  }
}