
import { authStore } from "@/modules/auth/store";
import { useMessage } from "@plugins/Messages-plugin";
import { providerTechniciansService } from "@services/providerTechnicians.service";
import moment from "moment";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useCalendarRouting } from "../../composables/useCalendarRouting";
import { useProviders } from "../../composables/useProviders";
import { ProviderTechnicianRoutesEnum } from "../../router";
import providerTechnicianStore from "../../store";

export default defineComponent({
  setup() {

    const { t } = useI18n();

    const technicianId = computed(
      () => authStore.getters.me?.id 
    )

    const availabilities = computed(
      () => providerTechnicianStore.getters.vueCalAvailabilities
    )

    const {
      startDate, 
      currentMonth,
      checkQueryParams,
      updateRoute
    } = useCalendarRouting(ProviderTechnicianRoutesEnum.AVAILABILITIES)

    const {
      providers,
      selectedProvider
    } = useProviders({
        technicianId: technicianId.value,
        forceSelection: true
      })
    
    function onViewChange(event: any) {
      currentMonth.value = new Date(event.startDate);
      updateRoute()
      loadAvailabilities()
    }

    const { errorMessage } = useMessage();

    async function onCellClick(cell, events){
      if (!selectedProvider.value?.id) {
        errorMessage(
          t('provider_technician.availabilities.select_provider_msg')
        );

        return; 
      }

      const e = events[0]?.data;
      if (e?.status === "BUY00") {
        errorMessage(
          t('provider_technician.availabilities.cannot_toggle_booked_slot')
        );

        return; 
      }

      if (isPast(cell.formattedDate)) {
        errorMessage(
          t('provider_technician.availabilities.cannot_select_past_date')
        );

        return; 
      }

      await providerTechniciansService.toggleAvailability(
        technicianId.value,
        {
          date_at: cell.formattedDate as string,
          provider_id: selectedProvider.value?.id
        } 
      )

      // if (e) {
      //   successMessage( t('provider_technician.availabilities.removed') )
      // } else {
      //   successMessage( t('provider_technician.availabilities.added') )
      // }    

      loadAvailabilities(selectedProvider.value?.id);
    }

    function isPast(date: any) {
      return moment(date).isBefore(moment(), 'day');
    }

    function getClassCell(cell: any, events: any[]) {
      const result = [];

      if (isPast(cell.formattedDate)) {
        result.push("past");
      }

      if (!events?.length) {
        result.push("no-events");
        return result.join(" ");
      }

      const e = events[0]?.data;
      if (e.provider_id !== selectedProvider.value?.id) {
        result.push("other-provider");
        return result.join(" ");
      } 

      if (!e?.order_id) {
        result.push("available");
      } 
 
      if (e?.order_id) {
        result.push("sold-out--orange");
      }

      return result.join(" ");
    }

    function loadAvailabilities(providerId?: number) {
      providerTechnicianStore.actions.fetchAvailabilities({
        technicianId: technicianId.value,
        providerId,
        month: moment(currentMonth.value).format('YYYY-MM') 
      });
    }

    checkQueryParams()

    loadAvailabilities()

    return {
      startDate,
      providers,
      availabilities,
      selectedProvider,

      getClassCell,

      onViewChange,
      onCellClick
    }
  }
})
