<template>
  <div 
    v-if="order"
    class="slot slot--planned"
  >
    <div>
      <div class="slot__subtitle">
        <template v-if="isAnonymous">
          {{$t('anonymous_purchase')}}
          <pre class="anonymous_text">{{anonymousText}}</pre>
        </template>
        <template v-else>
          {{ $t('purchased_by') }} {{customerName}}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 
    'order'
  ],

  computed: {
    isAnonymous() {
      return this.order.is_anonym_customer;
    },
    customerName() {
      return this.order.customer_name;
    },
  }
}
</script>