
import { authStore } from "@/modules/auth/store";
import { providerTechniciansService } from "@services/providerTechnicians.service";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ServicesPage",
})
export default class ServicePage extends Vue  {
  /**
   * Search result on dialog  
   */
  servicesFound = null; 
  get servicesFoundFiltered() {
    return this.servicesFound.filter(s => 
      s.name.toLowerCase().includes(this.search.toLowerCase())
    );
  } 

  search: string = "";

  /**
   * Selected services to add
   */
  selectedServices = [];

  /**
   * Current technician services
   */
  myServices = null;

  /**
   * Current technician id 
   */
  get technicianId() {
    return authStore.getters.me?.id;
  }

  isAdded(s) {
    return this.selectedServices.some(ss => ss.id === s.id);
  }
  
  selectService(s) {
    this.selectedServices.push(s);
  }

  unselectService(s) {
    this.selectedServices = this.selectedServices.filter(ss => ss.id !== s.id);
  }

  onHideDialog() {
    this.servicesFound    = null;
    this.selectedServices = [];
  }

  displayAddServiceDialog: boolean = false;
  selectedProviderId: number = null; 
  onAddService(providerId: number) {
    this.displayAddServiceDialog = true;
    this.selectedProviderId = providerId; 
    this.findServices(providerId);
  }

  async confirm() {
    const promises = this.selectedServices.map(s => {
      providerTechniciansService.toggleService(
        this.technicianId, 
        { 
          provider_id: this.selectedProviderId, 
          service_id: s.id
        }
      ); 
    });

    await Promise.all(promises)
      .then(() => {
        this.loadServices(); 
        this.displayAddServiceDialog = false; 

        this.$successMessage(
          this.$t('service_page.add_services.success')
        );
    });
  }

  async removeService(provider_id: number, service_id: number) {
    const response = await this.$confirmMessage(
      this.$t('service_page.remove_service.msg')
    );

    if (response) {
      this.$waitFor( async () => {
        await providerTechniciansService.toggleService( this.technicianId,{
          provider_id, service_id
        });

        this.loadServices();

        this.$successMessage(
          this.$t('service_page.remove_service.msg_success')
        )
      })
    }
  }

  async loadServices() {
    const response = 
      await providerTechniciansService.services(this.technicianId);

    this.myServices = response;
  }

  async toggleService(provider_id: number, service_id: number) {
    await providerTechniciansService.toggleService(
      this.technicianId, 
      { provider_id, service_id }
    ); 
  }

  isSearchingForServices: boolean = false;
  async findServices(provider_id: number) {
    try {
      this.isSearchingForServices = true;

      this.servicesFound = await providerTechniciansService.compatibleServices(
        this.technicianId, 
        { provider_id } 
      );
    } 
    catch (error) {
      console.error(error);
    }
    finally {
      this.isSearchingForServices = false;
    }
  }

  created() {
    this.loadServices();
  }

}
