
import { Appointment } from "@/model/api/appointment";
import { Provider } from "@/model/api/Provider";
import { authStore } from "@/modules/auth/store";
import {
DaySlots
} from "@components";
import moment from "moment";
import { computed, defineComponent, nextTick, ref, Ref } from "vue";
import { useCalendarRouting } from "../../composables/useCalendarRouting";
import { useProviders } from "../../composables/useProviders";
import { ProviderTechnicianRoutesEnum } from "../../router";
import providerTechnicianStore from "../../store";
import CustomerOrderSlot from "../../components/CustomerOrderSlot.vue";

export default defineComponent({
  components: {
    DaySlots,
    CustomerOrderSlot
  },

  setup() {
    const {
      startDate, 
      currentMonth,
      checkQueryParams,
      updateRoute
    } = useCalendarRouting(ProviderTechnicianRoutesEnum.APPOINTMENTS)

    const appointmentSection = ref(null);
    
    const selectedAppointment = ref<Appointment>(null);

    const technicianId = computed(
      () => authStore.getters.me?.id 
    )

    const appointments = computed(
      () => providerTechnicianStore.getters.vueCalAppointments 
    )

    function onProviderChange() {
      console.debug("onProviderChange")
      selectedAppointment.value = null;
    }

    function onViewChange(event: any) {
      currentMonth.value = new Date(event.startDate);

      updateRoute()

      loadAppointments()
    }

    function onCellClick(date) {
      const formattedDate = date?.format("YYYY-MM-DD");
      selectedAppointment.value = appointments.value.find(a => {
        return a.start === formattedDate;
      })?.data;

      const status = selectedAppointment.value?.status;
      if(status === "BUY00") {
        nextTick( () => {
          appointmentSection.value.scrollIntoView({
            behavior: 'smooth',
          });
        })
      }
    }

    function getOrderByTime(time: string) {
      const { customer_orders } = selectedAppointment.value;

      return customer_orders?.find(co => {
        return co.service_time_slot_from_at?.startsWith(time)
      });
    }

    function isPast(date: any) {
      return moment(date).isBefore(moment(), 'day');
    }

    function getClassCell(cell: any, events: any[]) {
      const result = [];

      if (isPast(cell.formattedDate)) {
        result.push("past");
      }

      if (!events?.length) {
        result.push("no-events");
        return result.join(" ");
      }


      const e = events?.[0];
      if (!e?.data?.order_id) {
        result.push("available");
      } 

      if (e?.data?.order_id) {
        result.push("sold-out--orange");
      }

      return result.join(" ");
    }

    function loadAppointments(providerId?: number) {
      providerTechnicianStore.actions.fetchAppointments({
        technicianId: technicianId.value,
        providerId,
        month: moment(currentMonth.value).format('YYYY-MM') 
      });
    }

    checkQueryParams()

    loadAppointments()

    return {
      startDate,
      appointments,
      selectedAppointment,
      ...computedFromAppointment(selectedAppointment),
      ...useProviders({
        technicianId: technicianId.value,
        callback: (provider: Provider) => {
          loadAppointments(provider?.id);
        }
      }),

      getClassCell,
      
      onProviderChange,
      onViewChange,
      onCellClick,
      appointmentSection,
      getOrderByTime
    }
  },
})

function computedFromAppointment(a: Ref<Appointment>) {
    const providerName = computed(() => {
      return a.value?.provider?.business_name;
    })

    const order = computed(() => {
      return a.value?.order;
    })

    const serviceName = computed(() => {
      return order.value.service.name;
    })

    const posName = computed(() => {
      return order.value?.pos.business_name;
    })

    const posAddress = computed(() => {
      return order.value?.pos.address || ' - ';
    })
    
    const slotDuration = computed(() => {
      return order.value?.provider_service.duration;
    })

    const serviceFrom = computed(() => {
      if(!order.value?.pos_portfolio.service_time_from_at) return null;

      return Number.parseInt(
        order.value?.pos_portfolio.service_time_from_at.substring(0,2)
      )
    })

    const serviceTo = computed(() => {
      if(!order.value?.pos_portfolio.service_time_to_at) return null;

      return Number.parseInt(
        order.value?.pos_portfolio.service_time_to_at.substring(0,2)
      )
    })

    const serviceTimeRange = computed(() => {
      const {
        service_time_from_at: from,
        service_time_to_at: to,
      } = order.value?.pos_portfolio;

      if (!from || !to) {
        return `Non impostata`;
      }

      return `${from.slice(0, -3)} - ${to.slice(0, -3)}`;
    })

    return {
      providerName,
      serviceName,
      serviceTimeRange,
      slotDuration,
      serviceFrom,
      serviceTo,
      posName,
      posAddress,
    }
}
