import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page--mobile" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { for: "search" }
const _hoisted_4 = {
  key: 0,
  class: "p-text-center"
}
const _hoisted_5 = {
  key: 1,
  class: "p-text-center"
}
const _hoisted_6 = {
  key: 2,
  class: "services"
}
const _hoisted_7 = { class: "service__name" }
const _hoisted_8 = { class: "service__action" }
const _hoisted_9 = { class: "p-d-flex p-jc-between" }
const _hoisted_10 = { class: "provider__name" }
const _hoisted_11 = { class: "services" }
const _hoisted_12 = { class: "service__name" }
const _hoisted_13 = { class: "service__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("header", null, [
      _createVNode("h1", null, _toDisplayString(_ctx.$t('service_page.title')), 1)
    ]),
    _createVNode(_component_PDialog, _mergeProps({
      modal: true,
      class: "p-fluid",
      keepInViewPort: true,
      minX: 0,
      minY: 0,
      header: _ctx.$t('service_page.add_service.title')
    }, _ctx.$attrs, {
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onHideDialog())),
      ref: "modal",
      style: { 'width': '400px'},
      visible: _ctx.displayAddServiceDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.displayAddServiceDialog = $event))
    }), {
      footer: _withCtx(() => [
        _createVNode("div", _hoisted_9, [
          _createVNode(_component_Button, {
            label: _ctx.$t('common.cancel'),
            icon: "pi pi-times",
            class: "p-button-secondary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.displayAddServiceDialog = false))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('common.confirm'),
            icon: "pi pi-check",
            class: "p-button-success",
            onClick: _ctx.confirm
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('service_page.search_label')), 1),
          _createVNode(_component_InputText, {
            id: "search",
            autofocus: "",
            autocorrect: "false",
            autocomplete: "off",
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.search = $event)),
            placeholder: _ctx.$t('service_page.search_placeholder')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        (_ctx.isSearchingForServices)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("i", null, _toDisplayString(_ctx.$t('loading_label')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.servicesFound?.length === 0)
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              _createVNode("i", null, _toDisplayString(_ctx.$t('service_page.no_results_message')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.servicesFound?.length)
          ? (_openBlock(), _createBlock("div", _hoisted_6, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.servicesFoundFiltered, (s) => {
                return (_openBlock(), _createBlock("div", {
                  class: "service",
                  key: `search_s_${s.id}`
                }, [
                  _createVNode("span", _hoisted_7, _toDisplayString(s.name), 1),
                  _createVNode("span", _hoisted_8, [
                    (!_ctx.isAdded(s))
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          icon: "pi pi-plus",
                          onClick: ($event: any) => (_ctx.selectService(s))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.isAdded(s))
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          icon: "pi pi-minus",
                          class: "p-button-danger",
                          onClick: ($event: any) => (_ctx.unselectService(s))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["header", "visible"]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myServices, (ms, i) => {
      return (_openBlock(), _createBlock("div", {
        class: "provider card",
        key: `ms_${i}_${ms.provider.id}`
      }, [
        _createVNode("header", null, [
          _createVNode("h4", _hoisted_10, [
            _createVNode("strong", null, _toDisplayString(ms.provider.business_name), 1)
          ]),
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_add_new_label'),
            icon: "pi pi-plus",
            iconPos: "right",
            class: "p-button-success p-button-sm",
            onClick: ($event: any) => (_ctx.onAddService(ms.provider.id))
          }, null, 8, ["label", "onClick"])
        ]),
        _createVNode("div", _hoisted_11, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(ms.services, (s) => {
            return (_openBlock(), _createBlock("div", {
              class: "service",
              key: `s_prov_${ms.provider.id}_${s.id}`
            }, [
              _createVNode("span", _hoisted_12, _toDisplayString(s.name), 1),
              _createVNode("span", _hoisted_13, [
                _createVNode(_component_Button, {
                  icon: "pi pi-times",
                  onClick: ($event: any) => (_ctx.removeService(ms.provider.id, s.id))
                }, null, 8, ["onClick"])
              ])
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}